import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appAutoInputWidthResize]',
  standalone: true,
})
export class AutoInputWidthResizeDirective implements OnChanges {
  @Input('appAutoInputWidthResize') value = '';

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'].currentValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      this.setWidth(changes['value'].currentValue.length);
    }
  }

  private setWidth(valueLength: number): void {
    const newWidth = valueLength + 1 + 'ch'; // Add some buffer for padding
    this.renderer.setStyle(this.el.nativeElement, 'width', newWidth);
  }
}
