<div class="flex items-center gap-x-2">
  <input
    [id]="controlName"
    [formControl]="control"
    type="checkbox"
    class="form-checkbox size-5 rounded border border-grey-midGrey"
    role="checkbox"
  />
  <span class="text-sm text-grey">
    <ng-container *ngTemplateOutlet="label"></ng-container>
  </span>
</div>
