import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function forbiddenCharactersValidator(): ValidatorFn {
  const forbiddenChars = /[<>;\\(){}/=*|$]/;
  return (control: AbstractControl<string>): ValidationErrors | null => {
    const forbidden = forbiddenChars.test(control.value);
    return forbidden ? { forbiddenCharacters: { value: control.value } } : null;
  };
}

export const emailValidationPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';

export function vatRegex(country: string): string {
  switch (country) {
    case 'AT': // Austria
      return '^ATU[0-9]{8}$';
    case 'BE': // Belgium
      return '^BE0[0-9]{9}$';
    case 'BG': // Bulgaria
      return '^BG[0-9]{9,10}$';
    case 'CH': // Switzerland
      return '^(CHE-[0-9]{3}.[0-9]{3}.[0-9]{3} +(IVA|TVA|MWST))$';
    case 'CY': // Cyprus
      return '^CY[0-9]{8}L$';
    case 'CZ': // Czech Republic
      return '^CZ[0-9]{8,10}$';
    case 'DE': // Germany
      return '^DE[0-9]{9}$';
    case 'DK': // Denmark
      return '^DK[0-9]{8}$';
    case 'EE': // Estonia
      return '^EE[0-9]{9}$';
    case 'ES': // Spain
      return '^ES[A-Z][0-9]{7}[A-Z]$|^ES[A-Z][0-9]{7}[0-9A-Z]$|^ES[0-9]{8}[A-Z]$';
    case 'GB': // UK
      return '^GB[0-9]{9}$|^GB[0-9]{12}$|^GBGD[0-9]{3}$|^GBHA[0-9]{3}$|^XI[0-9]{9}$';
    case 'EL': // Greece (also uses prefix "GR")
    case 'GR':
      return '^EL|GR[0-9]{9}$';
    case 'FI': // Finland
      return '^FI[0-9]{8}$';
    case 'FR': // France
      return '^FR[0-9A-Z]{2}[0-9]{9}$';
    case 'HU': // Hungary
      return '^HU[0-9]{8}$';
    case 'IE': // Ireland
      return '^IE[0-9]S[0-9]{5}L$';
    case 'IS': // Iceland
      return '^IS[0-9]{5,6}$';
    case 'IT': // Italy
      return '^IT[0-9]{11}$';
    case 'LT': // Lithuania
      return '^LT([0-9]{9}|[0-9]{12})$';
    case 'LU': // Luxembourg
      return '^LU[0-9]{8}$';
    case 'LV': // Latvia
      return '^LV[0-9]{11}$';
    case 'MT': // Malta
      return '^MT[0-9]{8}$';
    case 'NL': // Netherlands
      return '^NL[0-9]{9}B[0-9]{2}$';
    case 'NO': // Norway
      return '^NO[0-9]{9}MVA$';
    case 'PL': // Poland
      return '^PL[0-9]{10}$';
    case 'PT': // Portugal
      return '^PT[0-9]{9}$';
    case 'RO': // Romania
      return '^RO[0-9]{2,10}$';
    case 'SE': // Sweden
      return '^SE[0-9]{12}$';
    case 'SI': // Slovenia
      return '^SI[0-9]{8}$';
    case 'SK': // Slovakia
      return '^SK[0-9]{10}$';
    default:
      return '';
  }
}

export function getVatType(country: string) {
  const euCountries = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
  ];
  if (euCountries.includes(country)) {
    return 'eu_vat';
  }
  switch (country) {
    case 'IS':
      return 'is_vat';
    case 'NO':
      return 'no_vat';
    case 'CH':
      return 'ch_vat';
    case 'GB':
      return 'gb_vat';
    default:
      return '';
  }
}
