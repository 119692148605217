import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

export enum ButtonColor {
  BLUE = 'blue',
  RED = 'red',
}

@Component({
  selector: 'app-text-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-button.component.html',
  styleUrl: './text-button.component.css',
})
export class TextButtonComponent {
  @Input() color: ButtonColor;
  @Input() isLoading = false;
}
