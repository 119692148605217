<div class="flex items-center space-x-2">
  <button
    (click)="decrement()"
    class="rounded-lg border px-2.5 py-0 hover:bg-gray-300"
  >
    -
  </button>

  <input
    #quantityInput
    [formControl]="quantityControl"
    type="number"
    class="min-w-6 border-none text-center hover:border-none"
    min="1"
    aria-label="quantity"
    required
    name="quantity"
    (keydown.enter)="onEnterPress()"
    (focusout)="onFocusOut()"
    [appAutoInputWidthResize]="quantityControl.value?.toString() ?? '1'"
  />

  <button
    (click)="increment()"
    class="rounded-lg border px-2 py-0 hover:bg-gray-300"
  >
    +
  </button>
</div>
