import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';

// @todo: remove transcolo dependency from dumb components
@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [ReactiveFormsModule, TranslocoDirective],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss',
})
export class TextInputComponent {
  @Input({ required: true }) control: FormControl = new FormControl();
  @Input({ required: true }) controlName!: string;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() inputHint?: string;
  @Input() type?: string = 'text';

  // Error key in formControl must have a corresponding key in the translation files
  controlErrorsToArray(errorObj: ValidationErrors): string[] {
    return Object.keys(errorObj);
  }
}
