import { Component, EventEmitter, Input, Output, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { AutoInputWidthResizeDirective } from '../auto-input-width-resize.directive';

@Component({
  selector: 'app-increment-button',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, AutoInputWidthResizeDirective],
  templateUrl: './increment-button.component.html',
  styleUrls: ['./increment-button.component.css'],
})
export class IncrementButtonComponent implements OnInit {
  @Input() quantity = 1;
  @Input() minValue: number;
  @Output() quantityChange: EventEmitter<number> = new EventEmitter<number>();

  readonly positiveIntegerRegex = /^[0-9]+$/;

  @ViewChild('quantityInput') quantityInput!: ElementRef<HTMLInputElement>;
  quantityControl: FormControl<number | null>;

  ngOnInit(): void {
    if (!this.minValue) {
      this.minValue = 1;
    }

    this.quantityControl = new FormControl(this.quantity, {
      validators: [Validators.min(this.minValue), Validators.max(10e3), Validators.pattern(this.positiveIntegerRegex)],
      updateOn: 'blur',
    });

    this.quantityControl.setValue(this.quantity);
    this.emitQuantityChange();

    this.quantityControl.valueChanges.subscribe((value: number | null) => {
      if (this.quantityControl.valid) {
        this.quantityChange.emit(value ?? this.minValue);
      } else {
        this.quantityControl.setValue(this.minValue);
        this.quantityChange.emit(this.minValue);
      }
    });
  }

  increment(): void {
    if (this.quantityControl.valid && this.quantityControl.value !== null) {
      this.quantityControl.setValue(Number(this.quantityControl.value) + 1);
    }
  }

  decrement(): void {
    if (this.quantityControl.valid && this.quantityControl.value !== null && this.quantityControl.value > 1) {
      this.quantityControl.setValue(Number(this.quantityControl.value) - 1);
    }
  }

  private emitQuantityChange(): void {
    this.quantityChange.emit(this.quantityControl.value ?? 1);
  }

  private resetQuantity(): void {
    this.quantityControl.setValue(1);
    this.emitQuantityChange();
  }

  onEnterPress(): void {
    if (!this.quantityControl.valid) {
      this.resetQuantity();
    }
    this.quantityInput.nativeElement.blur();
  }

  onFocusOut(): void {
    if (!this.quantityControl.valid) {
      this.resetQuantity();
    }
  }
}
